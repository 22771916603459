import React, {
  Component,
  PureComponent,
  useRef,
  useState,
  useEffect,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getCourse,
  addToFavourite,
} from "../../../store/protected/courses/action";
import { setCartItem } from "../../../store/protected/cart/action";
import { ToastContainer, toast } from "react-toastify";

//const Entrepreneurship = (props) => {
class NewEntrepreneurship extends Component {
  state = {
    settings: {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    showLoginModel: false,
    selectedTab: 1,
  };

  onSelectedTab = (value) => {
    //setSelectedTab(value)
    this.setState({
      ...this.state,
      selectedTab: value,
    });
  };

  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
    // = "Courses :: Maetrix";
    this.props.dispatchCourse({
      token: this.props.token,
    });
    if (this.props.token) {
      if (localStorage.getItem("courses")) {
        this.setState(
          {
            ...this.state,
            localCart:
              localStorage.getItem("courses").length > 0
                ? JSON.parse(localStorage.getItem("courses")).map((cart) => {
                    return {
                      service_id: cart?.id,
                      service_category: cart?.service_category,
                    };
                  })
                : [],
          },
          () => {
            this.props.dispatchAddToCart({
              token: this.props.token,
              data: this.state.localCart,
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.getCourses != this.props?.getCourses &&
      this.props?.getCourses != null
    ) {
      this.setState({
        ...this.state,
        getCourseList: this.props?.getCourses?.data,
      });
    }
    if (
      prevProps?.isFavourite != this.props.isFavourite &&
      this.props.isFavourite != null
    ) {
      if (this.props.token) {
        this.props.dispatchCourse({
          token: this.props.token,
        });
        // window.location.reload();
      } else {
        this.props.dispatchCourse({});
      }
    }
  }

  isFavourite = (id) => {
    console.log(this.props);
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    return (
      <section className="entrepreneurship my-5">
        {this.state.getCourseList &&
          this.state.getCourseList.map((list, index) => (
            <div>
              {list?.courses?.length > 0 && (
                <div>
                  <div className="container" key={index}>
                    <div className="row">
                      <div className="col-12 col-md-12 text-center">
                        <h2>
                          {list.category_name}{" "}
                          <span>
                            <Link
                              to={`/course/${list.category_title_url}`}
                              className="view-all-btn"
                            >
                              View all{" "}
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="container mt-4 mb-5">
                    <div className="card-slider-new">
                      <Slider {...this.state.settings}>
                        {list?.courses &&
                          list?.courses?.map((element, index) => (
                            <div className="col-lg-12 pr-5" key={index}>
                              <div
                                className="card w-100"
                                style={{ position: "relative" }}
                              >
                                <button
                                  onClick={() => this.isFavourite(element?.id)}
                                >
                                  {element.is_favourite ? (
                                    <img
                                      class="card-img-sub"
                                      src="./assets/new_layout/img/heart.png"
                                    />
                                  ) : (
                                    <img
                                      class="card-img-sub"
                                      src="./assets/new_layout/img/heart-1.png"
                                    />
                                  )}
                                </button>

                                <img
                                  loading="eager"
                                  className="card-img-top card-img-top-custom-boxset"
                                  src={element.thumbnail_url}
                                  alt="Card image cap"
                                />
                                {/*<Link
                                to={`/course/${element.course_category_url}/${element.id}/${element.course_title_url}`}
                                  >*/}
                                <Link
                                  to={`/course/${element.course_category_url}/${element.id}/${element.course_title_url}`}
                                >
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {element?.course_name?.length <= 50
                                        ? element?.course_name
                                        : element?.course_name?.substring(
                                            0,
                                            50
                                          ) + "..."}
                                    </h5>
                                    <p>{element.no_of_chapters} Lessons</p>
                                    <a href="" className="btn-free">
                                      {element.course_type === 0 &&
                                      (element.is_subscribed === true ||
                                        element.is_subscribed === false) &&
                                      (element.is_independent === true ||
                                        element.is_independent === false)
                                        ? "Included in Subscription"
                                        : element.course_type === 1 &&
                                          element.is_independent === false &&
                                          element.is_subscribed === false
                                        ? `£ ${element.monthly_fee}`
                                        : element.course_type === 1 &&
                                          element.is_independent === true &&
                                          element.is_subscribed === false
                                        ? `£ ${element.course_fee}`
                                        : "View Course"}
                                    </a>
                                    <p className="mt-4">
                                      {[...Array(element.rating)].map(
                                        (elementInArray, index1) => (
                                          <img
                                            src={
                                              "./assets/new_layout/img/Path 9.png"
                                            }
                                          />
                                        )
                                      )}
                                      <small>({element.rating}.0)</small>
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
    getCourses: state.coursesReducer.courses,
    isFavourite: state?.coursesReducer?.isFavourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    dispatchCourse: (data) => dispatch(getCourse(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewEntrepreneurship));
