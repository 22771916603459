import React, { Component } from "react";
import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import FunQuestionAnswer from "./FunQuestionAnswer";
import { BASE_URL, CLIENT_ID } from "../../../helpers/Constants";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
// import FacebookLogin from 'react-facebook-login';

import axios from "axios";

import {
  getUser,
  getUserPaymentDetail,
} from "../../../store/protected/user-profile/action";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HtmlParser from "react-html-parser";

class UserAccount extends Component {
  state = {
    login: false,
    data: {},
    fbKey: false,
  };


  responseFacebook = (response) => {
    console.log("response", response);
  };
  componentDidMount() {
    // document.title = "User Account :: The Maetrix";
    this.props.dispatchUser({
      token: this.props.token,
    });
    this.props.dispatchGetPaymentDetail({
      token: this.props.token,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.getUserDetail?.user != this.props.getUserDetail?.user &&
      this.props.getUserDetail?.user != null
    ) {
      this.setState({
        ...this.state,
        user: this.props.getUserDetail?.user,
      });
    }
  }

  handleclick() {
    console.log("click button");
    this.setState({
      fbKey: true,
    });
  }

  
  cancelSubscription = () => {
    const url = `${BASE_URL}api/cancelSubscription`;
    axios
      .post(
        url,
        {},
        {
          headers: {
            authorization: "Bearer" + this.props.token,
          },
        }
      )
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  componentClicked() {
    console.log("hello user");
  }

  render() {
    return (
      <div>
        <AuthHeader token={this.props.token} />

        <div className="container page-height">
          <div className="my-account-row">
            <div className="my-account-header">
              <img
                alt="img"
                src={
                  this.state?.user?.profile_image
                    ? this.state?.user?.profile_image
                    : "/assets/images/profile-img.jpg"
                }
              />
              <h2>
                {this.state?.user?.name ? this.state?.user?.name : "User Name"}
              </h2>
              <h6>
                {this.state?.user?.email
                  ? this.state?.user?.email
                  : "User Email"}
              </h6>
              <p>
                {this.state?.user?.bio ? HtmlParser(this.state?.user?.bio) : ""}
              </p>
            </div>
            {this.state?.user?.membership_details && (
              <div className="member-list">
                <h2>My Subscription</h2>
                <div className="row">
                  <div className="col-md-12">
                    <div className="member-box">
                      <h3>
                        Subscription Name:{" "}
                        {this.state?.user?.membership_details?.tag_name}
                      </h3>
                      <h4>
                        Subscription Duration :{" "}
                        {this.state?.user?.membership_details?.valid_from} to{" "}
                        {this.state?.user?.membership_details?.valid_to}
                      </h4>
                      <p>
                        Next Billing Date:{" "}
                        {this.state?.user?.membership_details?.next_due_date}
                      </p>
                      
                      <div className="cncl-box">
                        {(this?.state?.user?.membership_details
                          ?.subscription_type === "Free" &&
                          this?.state?.user?.membership_details
                            ?.subscription_canceled === false && this?.state?.user?.membership_details
                            ?.is_expired === false) ||
                        (this?.state?.user?.membership_details
                          ?.subscription_type === "Paid" &&
                          this?.state?.user?.membership_details
                            ?.subscription_canceled === false  && this?.state?.user?.membership_details
                            ?.is_expired === false) ? (
                          <button className="cncl-member">
                            Cancel Subscription
                          </button>
                        ) : (
                          /*<button
                            className="cncl-member"
                            data-toggle="modal"
                            data-target="#cancelMembership"
                          >
                            Cancel Subscription
                          </button>*/
                          
                           <Link className="dropdown-item" to="/buy-subscriptions">
                            <button
                            className="cncl-member" 
                          > 
                           Renew Subscription
                           </button>
                        </Link>
                         
                          
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="account-mid-sec">
              <h2>My Payments</h2>
              <div className="row">
                {this.props?.userPaymentDetail?.map((detail, index) => (
                  <div className="col-lg-3 col-md-6" key={index}>
                    <div className="my-pay-box">
                      <h3>{detail?.service_category}</h3>
                      <h5> {detail?.service_name}</h5>
                      <h6>
                        <span>£ {detail?.amount}</span>
                      </h6>
                      <ul>
                        {detail.payment_status === "succeeded" && (
                          <li>
                            <img
                              alt="pay success"
                              className="pay-succsess"
                              src="/assets/images/success-icon.png"
                            />
                            Your enrollment for this course successfully done.
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <FunQuestionAnswer token={this.props.token} /> */}
          </div>
          <div
            className="modal fade"
            id="cancelMembership"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="cncl-mbrshp">
                    <h2>Cancel Your Membership ?</h2>
                    <p>
                      Cancellation will be effective at the end of your current
                      billing cycle.
                    </p>
                    <p>You canrestart your membership anytime.</p>
                    <div className="btn-sec">
                      <button
                        onClick={this.cancelSubscription}
                        data-dismiss="modal"
                      >
                        Yes
                      </button>
                      <button className="cncl-no" data-dismiss="modal">
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer token={this.props.token} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getUserDetail: state.userReducer.user,
    fun_question: state.userReducer.fun_question,
    userPaymentDetail: state.userReducer?.userPaymentDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUser: (data) => dispatch(getUser(data)),
    dispatchGetPaymentDetail: (data) => dispatch(getUserPaymentDetail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserAccount));
