import React, { PureComponent, useRef, useState, useEffect } from "react";

import Login from "../../components/gaust/login";
import Header from "./../../layout/gaust/newLayout/Header";
import Aheader from "./../../layout/auth/Aheader";
import Footer from "./../../layout/gaust/newLayout/FooterHome";

const NotFound = (props) => {
  var state = JSON.parse(localStorage.getItem('state'));
  const [showLoginModel, setShowLoginModel] = useState(false);
  const myFunction = (value) => {
    setShowLoginModel(true);
    setTimeout(function () {
      console.log("Hello World");
      window.$("#login-model").modal("show");
    }, 200);
  };

  return (
    <React.Fragment>
      {state?.authReducer?.login_Status ? (<Aheader token={state.authReducer.user.token} /> ) : ( <Header hitHome={myFunction} /> )}
        <div style={{display:'flex',justifyContent:'center'}}>
          <img alt="img" src="/assets/images/404-error-img.png" />
        </div>
        <Footer />
        {showLoginModel === true && <Login />}
    </React.Fragment>
  );}

export default NotFound;
