import React from "react";
import { Redirect } from "react-router-dom";

//Auth Route
import CourseMain from "../components/auth/courses/MainCoursePage";
import BoxsetsMain from "../components/auth/boxsets/index";
import Setting from "../components/auth/account/setting";
import UserAccount from "../components/auth/account/userAccount";
import CourseDescription from "../components/auth/courses/course-description";
import Members from "../components/auth/members";
import MemberDetail from "../components/auth/members/MemberDetail";
import Videos from "../components/auth/videos";
import Podcast from "../components/auth/podcasts";
import SupportFeedback from "../components/auth/support-feedback";
import MyActivity from "../components/auth/my-activities";
import Blog from "../components/auth/blogs";
import Dashboard from "../components/auth/dashboard";
import CourseDetail from "../components/auth/courses/courseDetail";
import CategoryDetail from "../components/auth/courses/categorydetail";
import Cart from "../components/auth/cart";
import VideoDetail from "../components/auth/videos/VideoDetail";
import BlogDetail from "../components/auth/blogs/BlogDetail";
import CategoryDetails from "../components/auth/videos/categoryDetails";
import PodcastCategoryDetail from "../components/auth/podcasts/PodcastCategoryDetail";
import BlogCategoryDetail from "../components/auth/blogs/CategoryDetail";
import Search from "../components/auth/search/search";
import Success from "../components/auth/paymentStatus/SuccessPage";
import Failed from "../components/auth/paymentStatus/errorPage";
import NewHomePage from "../components/gaust/newHome/index2";
import LiveStreaming from "../components/auth/streaming/Main";
import TermCondition from "../components/auth/term&condition/Term&Condition";
import PrivacyPolicy from "../components/auth/term&condition/PrivacyPolicy";
import PodcastDetail from "../components/auth/podcasts/PodcastDetail";
import LandingPage from "../components/gaust/LandingPage/Landing";
import DirectPayment from "../components/auth/directPayment";
import NewUser from "../components/auth/directPayment/NewUser";
import Subscription from "../components/auth/subscription/subscription-list";
import LoyalityPoints from "../components/auth/loyalty-points/loyalty-points";
import Calendar from "../components/auth/calendar";
import AIWorkbook from "../components/auth/ai-workbook";
import AIContent from "../components/auth/ai-content";
import MaterixChat from "../components/auth/materix-chat";
import Roadmap from "../components/auth/roadmap";
import ContentGenerator from "../components/auth/content-generator";
import WeeklyTracker from "../components/auth/weekly-tracker";
import Affiliate from "../components/auth/affiliate/affiliate";
import ReferralPage from "../components/gaust/referral/referralPage";
import  Chat  from "../components/auth/clara-ai/Chat";
import  ChatAi  from "../components/auth/clara-ai/ChatAi";


const authProtectedRoutes = [
  // { path: "/courses", exact: true, component: CourseMain },
  { path: "/courses", exact: true, component: BoxsetsMain },
  { path: "/lp", exact: true, component: LandingPage },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/calendar", exact: true, component: Calendar },
  { path: "/home", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/new_home", exact: true, component: NewHomePage },
  //{ path: "/dashboard", exact: true, component: Dashboard },
  { path: "/dashboard", exact: true, component: NewHomePage },
  { path: "/user-settings", exact: true, component: Setting },
  { path: "/user/myaccount", exact: true, component: UserAccount },
  {
    path: "/course/:categoryName/:id/:name",
    exact: true,
    component: CourseDescription,
  },
  { path: "/members", exact: true, component: Members },
  { path: "/member/:id/:name", exact: true, component: MemberDetail },
  { path: "/videos", exact: true, component: Videos },
  { path: "/podcasts", exact: true, component: Podcast },
  { path: "/support", exact: true, component: SupportFeedback },
  { path: "/myactivity", exact: true, component: MyActivity },
  { path: "/ai-workbook", exact: true, component: AIWorkbook },
  { path: "/blogs", exact: true, component: Blog },
  {
    path: "/course/:categoryName/:id/:name/view-detail",
    exact: true,
    component: CourseDetail,
  },
  { path: "/course/:name", exact: true, component: CategoryDetail },
  { path: "/buy-subscriptions", exact: true, component: Subscription },
  { path: "/subscriptions", exact: true, component: Subscription },
  { path: "/cart", exact: true, component: Cart },
  { path: "/video/:categoryName/:name", exact: true, component: VideoDetail },
  { path: "/blog/:categoryName/:name", exact: true, component: BlogDetail },
  { path: "/video/:name", exact: true, component: CategoryDetails },
  { path: "/podcast/:name", exact: true, component: PodcastCategoryDetail },
  { path: "/blog/:name", exact: true, component: BlogCategoryDetail },
  { path: "/search/:text", exact: true, component: Search },
  { path: "/payment/status/payment-success", exact: true, component: Success },
  { path: "/payment/status/payment-failed", exact: true, component: Failed },
  { path: "/maetrixlive", exact: true, component: LiveStreaming },
  { path: "/terms-and-conditions", exact: true, component: TermCondition },
  { path: "/privacy-policy", exact: true, component: PrivacyPolicy },
  {
    path: "/podcast/:categoryName/:name",
    exact: true,
    component: PodcastDetail,
  },
  { path: "/direct-payment", exact: true, component: DirectPayment },
  { path: "/new-user", exact: true, component: NewUser },
  { path: "/affiliate", exact: true, component: Affiliate },
  { path: "/ai-content", exact: true, component: AIContent },
  { path: "/roadmap", exact: true, component: Roadmap },
  { path: "/maetrix-chat", exact: true, component: MaterixChat },
  { path: "/insight-engine", exact: true, component: ContentGenerator },
  { path: "/weekly-tracker", exact: true, component: WeeklyTracker },
  { path: "/referral", exact: true, component: ReferralPage },
  { path: "/clara-ai-assistant", exact: true, component: ChatAi },
  { path: "/clara-ai-assistant-chatgpt-model", exact: true, component: Chat },
];

export { authProtectedRoutes };
