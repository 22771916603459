import React, { Component, createRef } from "react";
import { BASE_URL } from "../../helpers/Constants";

import { logout } from "../../store/protected/auth/actions";
import { addTrackingData } from "../../store/protected/dashboard/action";
import axios from "axios";
import queryString from "query-string";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class AuthHeader extends Component {
  constructor(props) {
    super(props);
    // console.log('AuthHeader');
    // console.log(this.props);
    this.state = {
      notification: null,
      totalCartItem: null,
      searchText: "",
      sticky: { isSticky: false, offset: 0 },
    };
    this.headerRef = createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  // handle scroll event
  handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      this.setState({ sticky: { isSticky: true, offset: elHeight } });
    } else {
      this.setState({ sticky: { isSticky: false, offset: 0 } });
    }
  };

  userSharingRevertAdd = async () => {
    let urlQuery = queryString.parse(this.props.location.search);
    // console.log(window.location);
    // console.log(this.props.location);
    let url = `${BASE_URL}api/video_sharing_revert`;
    const response = await axios
      .post(
        url,
        {
          url: window.location.href,
          path_name: this.props?.location?.pathname,
          user_id: urlQuery?.q?.substr(3, urlQuery?.q?.length - 6),
        },
        {
          headers: {
            authorization: "Bearer" + this.props.token,
          },
        }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          this.props.dispatchLogout({
            token: this.props?.token,
            history: this.props?.history,
          });
        }
      });
  };

  getNotification = async () => {
    let url = `${BASE_URL}api/notificationList`;
    const response = await axios
      .get(url, {
        headers: {
          authorization: "Bearer" + this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          notification: res.data.result,
        });
      })
      .catch((error) => {
        if (error.response?.status == 401) {
          this.props.dispatchLogout({
            token: this.props?.token,
            history: this.props?.history,
          });
        }
      });
  };

  getCartItems = async () => {
    let url = `${BASE_URL}api/cartItems?token=${this.props.token}`;
    const response = await axios.get(url);
    this.setState({
      totalCartItem: response.data.result,
    });
  };

  componentDidMount() {
    this.getNotification();
    this.getCartItems();
    this.userSharingRevertAdd();
    var header = this.headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      this.handleScroll(header.top, header.height);
    };

    let urlQuery = queryString.parse(this.props.location.search);
    if (this.props.token && urlQuery && urlQuery.q) {
      console.log("Aheader");
      console.log(urlQuery);
      this.props.dispatchTrackingData({
        user_id: urlQuery?.q,
        url: window.location.href,
        type: "video",
        content_id: "",
        client_details: "",
      });
    }
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.cartItems !== this.props.cartItems) {
      //console.log('cart condition');
      this.getCartItems();
    }
  }
  onLogout = (event) => {
    this.props.dispatchLogout({
      token: this.props?.token,
      history: this.props.history,
    });
  };

  gotoNotification = async (link) => {
    let url = `${BASE_URL}api/markAsRead`;
    const response = await axios.post(
      url,
      {
        notification_id: link.id,
        mark_all_as_read: 0,
      },
      {
        headers: { authorization: `Bearer ${this.props.token}` },
      }
    );
    if (response.data) {
      if (link.notification_link !== null) {
        window.location.href = link.notification_link;
      } else {
        this.getNotification();
      }
    }
    return response;
  };

  goToCart = (event) => {
    this.props.history.push("/cart");
  };
  handleChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleSubmit = (e) => {
    if (this.state?.searchText == null || this.state?.searchText === "") {
      alert("Empty Search");
    } else {
      e.preventDefault();
      this.props.history.push(`/search/${this.state.searchText}`);
    }
  };

  backword = () => {
    window.location.pathname !== "/dashboard" && this.props.history.goBack();
  };

  readAllNotification = () => {
    //console.log('hello notification');
    let url = `${BASE_URL}api/markAsRead`;
    axios
      .post(
        url,
        {
          mark_all_as_read: 1,
        },
        {
          headers: { authorization: `Bearer ${this.props.token}` },
        }
      )
      .then((response) => {
        //console.log('response', response);
      });
  };

  render() {
    return (
      <div className="top-header-fixed">
        <div
          id="sticky-header"
          className={`${
            this.state.sticky.isSticky
              ? "header-sec header-sticky"
              : "header-sec header-sticky"
          }`}
          ref={this.headerRef}
        >
          <nav className="navbar navbar-expand-lg" id="navbar1">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand">
                <img
                  src={"/assets/new_layout/img/logo.png"}
                  className="img-fluid"
                />
              </Link>

              <div
                className="navbar-collapse me-auto"
                id="navbarSupportedContentNew"
              >
                {/* <Link to="/maetrixlive" className="nav-link live">
                  <img
                    className="default"
                    src={"/assets/new_layout/img/live.png"}
                  />
                  <img
                    className="hover"
                    src={"/assets/new_layout/img/live_hover.png"}
                  />
                </Link> */}
                <form className="d-flex">
                  <img
                    src={"/assets/new_layout/img/search.png"}
                    className="mt-2 pr-1"
                  />
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item ntfctn-sec show" id="new-Item-one">
                    <a
                      className="nav-link"
                      aria-current="page"
                      role="button"
                      data-toggle="dropdown"
                      onClick={this.readAllNotification}
                    >
                      <img src={"/assets/new_layout/img/notification.png"} />
                    </a>
                    {this.state?.notification?.unread_notifications > 0 && (
                      <span>
                        <strong>
                          {this.state?.notification?.unread_notifications}
                        </strong>
                      </span>
                    )}
                    <div className="dropdown-menu">
                      <div className="notification-box">
                        {this.state.notification?.notification_list?.map(
                          (list, index) => (
                            <div
                              className={
                                list.is_read == 1 ? "noti-bx1" : "noti-bx"
                              }
                              key={index}
                              onClick={() => this.gotoNotification(list)}
                            >
                              <img alt="icon" src={list.image} />
                              <h5>{list.notification_text}</h5>
                              <p>{list.time}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item" id="new-Item-new">
                    <Link to="/cart" className="nav-link">
                      <img
                        onClick={this.goToCart}
                        src={"/assets/new_layout/img/Cart.png"}
                      />
                      {this.state?.totalCartItem?.cart_items > 0 &&
                        this.state?.totalCartItem?.cart_items !== null && (
                          <span className="cartItemCount">
                            <strong>
                              {this.state?.totalCartItem?.cart_items}
                            </strong>
                          </span>
                        )}
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                                    <a className="nav-link" href="#"><img src={"./assets/new_layout/img/profile.png"} /></a>
                                </li>
                                 
                                <li>
                                   
                                    <button type="button" onClick={loginBtnClicked} className="loginbutton">
                                        Log In / Register
                                    </button>
                                </li>*/}
                </ul>

                <div className="header-top header-top-new">
                  <div className="header-top-right">
                    <ul>
                      <li className="profile">
                        <button
                          type="button"
                          className="dropdown-toggle dropdown-class"
                          data-toggle="dropdown"
                        >
                          <img
                            className="image-margin"
                            src={
                              this.props.getUpdateStatus?.updateUser?.data
                                ?.profile_image
                                ? this.props.getUpdateStatus?.updateUser?.data
                                    ?.profile_image
                                : "/assets/images/profile-img.jpg"
                            }
                            alt=""
                          ></img>
                        </button>
                        <div className="dropdown-menu">
                          <Link className="dropdown-item" to="/user/myaccount">
                            My Account
                          </Link>
                          <Link className="dropdown-item" to="/user-settings">
                            Profile
                          </Link>
                          <Link className="dropdown-item" to="/affiliate">
                            Affiliate Programme
                          </Link>
                          <Link className="dropdown-item" to="/subscriptions">
                            My Subscriptions
                          </Link>
                          <Link className="dropdown-item" to="/myactivity">
                            My Favourites
                          </Link>
                          <Link className="dropdown-item" to="/support">
                            Support & Feedback
                          </Link>
                          <Link className="dropdown-item" to="/ai-workbook">
                            CLARA Workbook
                          </Link>
                          {/* <Link className="dropdown-item" to="/ai-content">
                            Content Generator
                          </Link> */}
                          <Link className="dropdown-item" to="/maetrix-chat">
                            CLARA Chat
                          </Link>
                          <Link className="dropdown-item" to="/insight-engine">
                            Content Generator
                          </Link>
                          <Link className="dropdown-item" to="/weekly-tracker">
                            Weekly Tracker
                          </Link>
                          <a
                            className="dropdown-item"
                            onClick={this.onLogout}
                            style={{ borderBottom: "0px" }}
                          >
                            Logout
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("dashboard") ||
                        window.location.pathname.includes("dashboard")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      aria-current="page"
                      to="/"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("courses") ||
                        window.location.pathname.includes("course") ||
                        window.location.pathname.includes("courses")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/courses"
                    >
                      Courses
                    </Link>
                  </li>
                  {/* <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("calendar")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={"/calendar"}
                    >
                      Calendar
                    </Link>
                  </li> */}

                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("videos") ||
                        window.location.pathname.includes("/videos/") ||
                        window.location.pathname.includes("/video/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/videos"
                    >
                      Videos
                    </Link>
                  </li>
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("podcasts") ||
                        window.location.pathname.includes("/podcasts/") ||
                        window.location.pathname.includes("/podcast/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/podcasts"
                    >
                      Podcasts
                    </Link>
                  </li>
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("blogs") ||
                        window.location.pathname.includes("/blogs/") ||
                        window.location.pathname.includes("/blog/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/blogs"
                    >
                      Blogs
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={
                        window.location.pathname.includes("support") ||
                        window.location.pathname.includes("/support/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/support"
                    >
                      Support & Feedback
                    </Link>
                  </li> */}
                  {/* <li className="nav-item ml-4">
                    <a
                      className={
                        window.location.pathname.includes("subscriptions") ||
                        window.location.pathname.includes("subscriptions")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      href="subscriptions"
                    >
                      Subscription
                    </a>
                  </li> */}
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("member") ||
                        window.location.pathname.includes("/member/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/members"
                    >
                      Members
                    </Link>
                  </li>
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("ai-content") ||
                        window.location.pathname.includes("/ai-content/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/ai-content"
                    >
                      Content Generator
                    </Link>
                  </li>

                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("clara-ai-assistant") ||
                        window.location.pathname.includes("/clara-ai-assistant/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/clara-ai-assistant"
                    >
                      Clara.AI
                    </Link>
                  </li>
                
                  <li className="nav-item mx-2">
                    <Link
                      className={
                        window.location.pathname.includes("roadmap") ||
                        window.location.pathname.includes("/roadmap/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/roadmap"
                    >
                      Roadmap
                    </Link>
                  </li>
                  <li className="nav-item ml-4 hide-desktop">
                    <a className="nav-link" href="#" onClick={this.onLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getUserDetail: state.authReducer?.user?.user,
    getUpdateStatus: state.settingReducer,
    cartItems: state?.cartReducer?.cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogout: (data) => dispatch(logout(data)),
    dispatchTrackingData: (data) => dispatch(addTrackingData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthHeader));
