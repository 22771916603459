// src/ChatUI.js
import React, { Fragment, useState ,useRef,useEffect,CSSProperties} from 'react';
import './Chat.css';
import AuthHeader from "../../../layout/auth/Aheader";
import Footer from '../../../layout/gaust/newLayout/Footer';
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {ScaleLoader } from 'react-spinners';
import { ToastContainer, toast } from "react-toastify";

const ChatAi = (props) => {

    const listRef = useRef(null);
    const [messages,setMessages] = useState([
        {
            message : "Hello!, I'm CLARA, how may I help you?",
            sender :"ChatGPT",
            direction:"incoming"
        }
    ]);
    
    const scrollToBottom = () => {
      return listRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages]);

    const [typing,setTyping] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const API_KEY = "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";
    
    const [abort,setAbort] = useState(null);

    const handleSendMessage = async () => {
        
        if(inputValue === ''){
          toast.error("Please input your query to Clara!");
          return;
        }

        const newMessage = {
            message : inputValue,
            sender : "user",
            direction:"outgoing"
        }
        
        const newMessages = [...messages,newMessage];    
        setMessages(newMessages);
        setInputValue("");
        setTyping(true);
        await processMessageToChatGPT(newMessages);
    };

    const stopClara = () =>{
      if (abort){
        abort.abort();
        setTyping(false);
      }else{
        console.log("--------------Aborting failed-------------")
      }
    }


    async function processMessageToChatGPT(chatMessages){

        let apiMessages = chatMessages.map((messageObject) => {
    
          let role = "";
          if(messageObject.sender === "ChatGPT"){
            role = "assistant";
          }else{
            role = "user";
          }
    
          return {role :role, content : messageObject.message}
        });
    
        const systemMessage = {
            role : "system",
            content : "Explain all conecpts like I am 10 years old."
        }
    
        const apiRequestBody = {
            "model" : "gpt-3.5-turbo",
            //"model" : "ft:gpt-3.5-turbo-1106:aesthetic-entrepreneurs::8LKKIfM5",
            "messages" :[
                // systemMessage,
                ...apiMessages
            ],
            "stream":true
        }

        let abort = new AbortController();
        setAbort(abort);

        try {
            var message = '';
            await fetchEventSource('https://api.openai.com/v1/chat/completions', {
              method: "POST",
              signal: abort.signal,
              headers: {
                Accept: "text/event-stream",
                "Content-Type": "application/json",
                Authorization: "Bearer " + API_KEY,
              },
              body: JSON.stringify(apiRequestBody),
              onopen(res) {
                if (res.ok && res.status === 200) {
                  // console.log("Connection made ", res);
                } else if (
                  res.status >= 400 &&
                  res.status < 500 &&
                  res.status !== 429
                ) {
                  console.log("Client side error ", res);
                }
              },
              onmessage(event) {
                try {
                  if (event.data != "[DONE]") {
                    const parsedData = JSON.parse(event.data);
                    if (
                      parsedData &&
                      parsedData.choices &&
                      parsedData.choices.length > 0 &&
                      parsedData.choices[0]?.delta &&
                      parsedData.choices[0]?.delta.content
                    ) {
                        message = message + parsedData.choices[0]?.delta.content;
                        setTimeout(() => {
                            setMessages(
                            [...chatMessages,
                                {
                                    message: message,
                                    sender:"ChatGPT",
                                    direction:"incoming"
                                }
                            ]
                        );
                      }, 1);
                    }
                  } else if (event.data == "[DONE]") {
                    setTyping(false);
                    console.log("finished");
                  }
                } catch (e) {}
              },
              onclose() {
                console.log("Connection closed by the server");
              },
              onerror(err) {
                console.log("There was an error from server", err);
              },
            });
          } catch (e) {
            console.log(e.message);
          }

      }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }

    return (
        <React.Fragment>
        <AuthHeader token={props.token} />
        <ToastContainer />
        <div className='chat-container'>
          <div className='chat-header'>
                Clara.AI
          </div>
          <div className='chat-main-div'>
            {messages.map((message, index) => (
              <div className={`main-${message.sender === 'user' ? 'user-messages' : 'bot-messages'}`} > 
                <img key={index} src={`${message.sender === 'user' ? '/assets/images/profile-img.jpg' : '/assets/images/ai.png'}`}  alt="User Avatar" class="avatar" />
                  <div key={index}  className={`message ${message.sender === 'user' ? 'user-message' : 'bot-message'}`}>
                    {message.message}
                  </div>
              </div>
            ))}
            <div ref={listRef} />
          </div>          
          <div className='input-div' style={{justifyContent:'center'}}>
          {  
            typing ?
              <>
                <div className="main-bot-messages" style={{justifyContent:'center',color:'white',alignItems:'center'}}>        
                <ScaleLoader
                  color='#ff914d'
                />
                Clara is loading content...
                <button onClick={stopClara}> Stop!</button>
                </div>
              </>
            : 
              <>
                <input
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Please type your message to ask Clara!"
                /> 
                <button onClick={handleSendMessage}>Ask Clara!</button> 
              </> 
          }   
          </div>
        </div>
        <Footer />
        </React.Fragment>
    );
};

export default ChatAi;
