import React, { Component } from "react";

import { BASE_URL } from "../../../helpers/Constants";

import axios from "axios";

class SuccessPage extends Component {
  componentDidMount() {
    // document.title = "Payment Success :: The Maetrix";
    if (localStorage.getItem("Checkout_Response")) {
      let localData = JSON.parse(localStorage.getItem("Checkout_Response"));
      let services = localData?.services;
      let session_id = localData?.session?.id;
      const url = `${BASE_URL}api/updateValidityAfterSubscriptionPaymentV2`;
      // const url = `${BASE_URL}api/updateValidityAfterSubscriptionPaymentTest`;
      axios
        .post(
          url,
          { session_id, services },
          {
            headers: {
              authorization: "Bearer" + this.props.token,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === true) {
            // localStorage.removeItem("Checkout_Response");
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error) {
            localStorage.removeItem("Checkout_Response");
            window.location.assign("/payment/status/payment-failed");
          }
        });
    }

    if (localStorage.getItem("cart_Checkout")) {
      let localData = JSON.parse(localStorage.getItem("cart_Checkout"));
      let services = localData?.services;
      let payment_intent = localData?.session?.payment_intent;
      const url = `${BASE_URL}api/buyServicesV2`;
      //const url = `${BASE_URL}api/buyServicesTest`;
      axios
        .post(
          url,
          { services, payment_intent },
          {
            headers: {
              authorization: "Bearer" + this.props.token,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === true) {
            localStorage.removeItem("cart_Checkout");
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error) {
            localStorage.removeItem("cart_Checkout");
            window.location.assign("/payment/status/payment-failed");
          }
        });
    }
  }
  render() {
    return (
      <div className="success-main">
        <div className="success-box">
          <img alt="img" className="tick" src="/assets/images/tick-img.png" />
          <img alt="img" src="/assets/images/success-img.jpg" />
          <h3>Success</h3>
          <p>
            Your payment would reflect in your payments section within 1 hour
          </p>
          <a href="/">
            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Home
          </a>
        </div>
      </div>
    );
  }
}

export default SuccessPage;
