import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";
import ResponsivePlayer from "../video-player/responsiveplayer";
import SharePopUpModel from "./SharedPopUpModel";
import {
  getVideoDetail,
  savePauseTime,
  getPauseTime,
} from "../../../store/protected/videos/action";
import { isLatestSuccess } from "../../../store/protected/courses/action";
import { setCartItem } from "../../../store/protected/cart/action";
import { BASE_URL, stripePromise } from "../../../helpers/Constants";
import ReactHtmlParser from "react-html-parser";
import isEqual from "lodash/isEqual";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SubscriptionPops from "./SubscriptionPopup"

class VideoDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      showLoginModel: false,
      showSharePopUp: false,
      showVideo: false,
      setValue: false,
      videoUrl: "",
      pageUrl: "",
      itemAdded: false,
      totalDuration: 0,
      perceentageValue: 0,
    };

    this.duration = 0;
    this.video = React.createRef();
  }

  componentDidMount() {
    // document.title = "Video Detail :: The Maetrix";
    this.props.dispatchVideoById({
      video_title_url: this.props.match.params.name,
      token: this.props.token,
    });
    this.props.token &&
      this.props.diaspatchGetPauseTime({
        qs: {
          service_category: "video",
          service_id: this.props?.videoDetail?.id,
        },
        token: this.props.token,
      });
    this.setState({
      ...this.state,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps?.cartItemSaved, this.props?.cartItemSaved) &&
      this.props?.cartItemSaved
    ) {
      toast.success("Item Successfully Add To Cart", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (
      !isEqual(prevProps?.getPauseTime, this.props?.getPauseTime) &&
      this.props?.getPauseTime
    ) {
      var hms = this.props?.getPauseTime?.resume_at;
      var a = hms.split(":");
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      if (seconds) {
        this.setState(
          {
            setValue: true,
          },
          () => {
            if (this.video.current) {
              this.video.current.seekTo(seconds);
            }
          }
        );
      }
    }
  }

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  openLogin = (event) => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  buySubscrition = (event) => {
    this.props.history.push("/buy-subscriptions");
  };
  saveToCart = (event) => {
    this.setState({
      itemAdded: true,
    });
    let service = {
      service_category: this.props?.videoDetail?.service_category,
      service_id: this.props?.videoDetail?.id,
    };
    this.setState(
      {
        services: this.state?.services?.concat(service),
      },
      () => {
        this.props.dispatchAddToCart({
          token: this.props.token,
          data: this.state.services,
        });
      }
    );
  };

  goBuySubscription = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    this.setState({
      videoUrl: url,
      pageUrl: window.location.href+qrstr,
    });
    window.$("#shareModel").modal("show");
  };

  handleDuration = (duration) => {
    this.setState({
      totalDuration: parseInt(duration),
    });
    this.videoAnalytic();
  };

  handleWatchComplete = ({ playedSeconds }) => {
    this.duration = parseInt(playedSeconds);
    if (parseInt((this.state.totalDuration * 10) / 100) === this.duration) {
      this.setState(
        {
          perceentageValue: 10,
        },
        () => {
          this.videoAnalytic();
        }
      );
    } else if (
      parseInt((this.state.totalDuration * 30) / 100) === this.duration
    ) {
      this.setState(
        {
          perceentageValue: 30,
        },
        () => {
          this.videoAnalytic();
        }
      );
    } else if (
      parseInt((this.state.totalDuration * 60) / 100) === this.duration
    ) {
      this.setState(
        {
          perceentageValue: 60,
        },
        () => {
          this.videoAnalytic();
        }
      );
    } else if (
      parseInt((this.state.totalDuration * 90) / 100) === this.duration
    ) {
      this.setState(
        {
          perceentageValue: 90,
        },
        () => {
          this.videoAnalytic();
        }
      );
    }
  };
  videoAnalytic = () => {
    let url = `${BASE_URL}api/videoAnalytics`;
    axios.post(
      url,
      {
        video_id: this.props?.videoDetail?.id,
        percentage: this.state.perceentageValue,
      },
      {
        headers: { authorization: `Bearer ${this.props?.token}` },
      }
    );
  };
  showPlayer = () => {
    this.setState({
      showVideo: true,
    });
  };

  logPopUp = () => {
    console.log("hello login", this.state.showLoginModel);
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };
  checkoutPopUp = async () => {
    const stripe = await stripePromise;
    //const url = `${BASE_URL}api/createSessionForPaymentV2Test`;
    const url = `${BASE_URL}api/createSessionForPaymentV2`;
    const response = await axios.post(
      url,
      {
        service_id: this.props?.videoDetail?.id,
        service_category: this.props?.videoDetail?.service_category,
        service_name: this.props?.videoDetail?.video_title,
      },
      {
        headers: {
          authorization: "Bearer" + this.props.token,
        },
      }
    );
    const apiResult = await response.data.result;
    localStorage.setItem("cart_Checkout", JSON.stringify(apiResult));
    const result = await stripe.redirectToCheckout({
      sessionId: apiResult?.session?.id,
    });
    if (result.error) {
      console.log("error", result.error.message);
    }
  };

  checkCondition = (event) => {
    window.$("#subsModel").modal("show");

    this.setState(
      {
        ...this.state,
        showSubscriptionModel: true,
      },
      () => {
      }
    );
    // alert("Popoup function!!!");
  }

  render() {
    console.log("props", this.props?.videoDetail);
    return (
      <React.Fragment>
        <SubscriptionPops 
          token={ this.props.token }
          videoDetail={this.props?.videoDetail}
        />
        <SharePopUpModel url={this.state.pageUrl} />
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <div className="single-video page-height">
          <ToastContainer />
          <div className="vid-prw-row">
            <div className="container">
              <div className="video-view">
                {this.state.showVideo === false ? (
                  <div className="video-prw">
                    <img alt="img" src={this.props?.videoDetail?.banner_url} />
                    <button
                      className="play-btn"
                      onClick={() => this.showPlayer()}
                    >
                      <img alt="img" src="/assets/images/Play-btn.png" />
                    </button>
                  </div>
                ) : this.props?.videoDetail?.without_login === true ? (
                  <ResponsivePlayer
                    url={
                      this.props?.videoDetail?.video_url
                        ? this.props?.videoDetail?.video_url
                        : ""
                    }
                    playing={true}
                    refToSet={this.video}
                  />
                ) : this.props.token ? (
                  this.props?.videoDetail?.video_type === 1 ? (
                    this.props?.videoDetail?.is_subscribed === 1 ? (
                      <ResponsivePlayer
                        url={
                          this.props?.videoDetail?.video_url
                            ? this.props?.videoDetail?.video_url
                            : ""
                        }
                        playing={true}
                        refToSet={this.video}
                        cbs={{
                          onStart: () => {
                            this.props.dispatchLatestService({
                              id: this.props?.videoDetail?.id,
                              category: "video",
                              token: this.props.token,
                            });

                            this.props.dispatchSetPauseTime({
                              service_id: this.props?.videoDetail?.id,
                              service_category: "video",
                              playble_item_id: this.props?.videoDetail?.id,
                              resume_at: "00:00:00",
                              token: this.props.token,
                            });
                          },

                          onPause: () => {
                            var date = new Date(0);
                            date.setSeconds(
                              Math.floor(Math.floor(this.duration)).toFixed()
                            );
                            var timeString = date.toISOString().substr(11, 8);
                            const pauseTime1 = timeString;
                            this.props.dispatchSetPauseTime({
                              service_id: this.props?.videoDetail?.id,
                              service_category: "video",
                              playble_item_id: this.props?.videoDetail?.id,
                              resume_at: pauseTime1,
                              token: this.props.token,
                            });
                          },

                          onProgress: this.handleWatchComplete,
                          onDuration: this.handleDuration,
                        }}
                      />
                    ) : (
                      <div className="videospace">
                        <div className="inbox">
                          <h3>
                            <span className="subtext">
                              {`You need the ${this.props?.videoDetail?.tag_name}  subscription or purchase this video to watch this video`}
                            </span>
                          </h3>
                          {this.props?.videoDetail.added_to_cart ||
                          this.state?.itemAdded === true ? (
                            <button className="btn btn-info">
                              Added To Cart
                            </button>
                          ) : (
                            <button
                              className="btn btn-info"
                              onClick={this.saveToCart}
                            >
                              Add To Cart
                            </button>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <button
                            onClick={this.buySubscrition}
                            className="btn btn-info"
                          >
                            Start subscription
                          </button>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <button
                            onClick={this.checkoutPopUp}
                            className="btn btn-info"
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                    )
                  ) : (
                    <ResponsivePlayer
                      url={
                        this.props?.videoDetail?.video_url
                          ? this.props?.videoDetail?.video_url
                          : ""
                      }
                      playing={true}
                      refToSet={this.video}
                      cbs={{
                        onStart: () => {
                          this.props.dispatchLatestService({
                            id: this.props?.videoDetail?.id,
                            category: "video",
                            token: this.props.token,
                          });

                          this.props.dispatchSetPauseTime({
                            service_id: this.props?.videoDetail?.id,
                            service_category: "video",
                            playble_item_id: this.props?.videoDetail?.id,
                            resume_at: "00:00:00",
                            token: this.props.token,
                          });
                        },
                        onPause: () => {
                          var date = new Date(0);
                          date.setSeconds(
                            Math.floor(Math.floor(this.duration)).toFixed()
                          );
                          var timeString = date.toISOString().substr(11, 8);
                          const pauseTime1 = timeString;
                          this.props.dispatchSetPauseTime({
                            service_id: this.props?.videoDetail?.id,
                            service_category: "video",
                            playble_item_id: this.props?.videoDetail?.id,
                            resume_at: pauseTime1,
                            token: this.props.token,
                          });
                        },
                        onProgress: this.handleWatchComplete,
                        onDuration: this.handleDuration,
                      }}
                    />
                  )
                ) : (
                  <div className="videospace">
                    <div className="inbox">
                      <h3>
                        <span className="subtext">
                          {`You need ${this.props?.videoDetail?.tag_name} subscription to watch this video.`}
                        </span>
                      </h3>
                      <button className="btn btn-info" onClick={this.openLogin}>
                        Login here
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="video-view-cnt">
                <h2>{this.props?.videoDetail?.video_title}</h2>
                <div className="share-social">
                  <h3>
                      {this.props?.videoDetail?.video_type === "0" &&
                      (this.props?.videoDetail?.is_subscribed === 1 ||
                        this.props?.videoDetail?.is_subscribed === 0)
                        ? <span> Free </span>
                        : this.props?.videoDetail?.video_type === '1' &&
                          this.props?.videoDetail?.is_subscribed === 1
                        ? <></>
                        : <button className="share"  onClick={this.checkCondition} >Buy for £{this.props?.videoDetail?.fee} </button> 
                        }
                    {!this.props.token ? (
                      <button
                        className="share"
                        onClick={() =>
                          this.goBuySubscription(
                            this.props?.videoDetail?.video_url
                          )
                        }
                      >
                        Share <i className="fa fa-share" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button
                        className="share"
                        onClick={() =>
                          this.goBuySubscription(
                            this.props?.videoDetail?.video_url
                          )
                        }
                      >
                        Share <i className="fa fa-share" aria-hidden="true"></i>
                      </button>
                    )}
                  </h3>
                </div>

                {ReactHtmlParser(this.props?.videoDetail?.description)}
                <p>
                  <strong>Duration : </strong>
                  {this.props?.videoDetail?.duration}
                  <span>
                    <strong> Date : </strong>
                    {this.props?.videoDetail?.created_at}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    videoDetail: state.videosReducer?.videoDetail,
    cartItemSaved: state.cartReducer?.addedResponse,
    getPauseTime: state.videosReducer?.getPauseTime,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchVideoById: (data) => dispatch(getVideoDetail(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchSetPauseTime: (data) => dispatch(savePauseTime(data)),
    diaspatchGetPauseTime: (data) => dispatch(getPauseTime(data)),
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoDetail));
